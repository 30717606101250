// extracted by mini-css-extract-plugin
export var backgroundImage = "BlogList-module--backgroundImage--13aca";
export var bold = "BlogList-module--bold--df584";
export var buttonList = "BlogList-module--buttonList--f0c65";
export var categoryContainer = "BlogList-module--categoryContainer--fa837";
export var categoryList = "BlogList-module--categoryList--3a263";
export var excerpt = "BlogList-module--excerpt--0ace2";
export var latestExcerpt = "BlogList-module--latestExcerpt--65aaf";
export var latestPostCardContainer = "BlogList-module--latestPostCardContainer--0e252";
export var latestPostCardContent = "BlogList-module--latestPostCardContent--d62e8";
export var latestPostCardTitle = "BlogList-module--latestPostCardTitle--60694";
export var latestPostCardWrapper = "BlogList-module--latestPostCardWrapper--21706";
export var multipleLatest = "BlogList-module--multipleLatest--7f0d0";
export var pagination = "BlogList-module--pagination--a7e4a";
export var pillarTag = "BlogList-module--pillarTag--66e63";
export var postCardContent = "BlogList-module--postCardContent--eb1fe";
export var postCardTitle = "BlogList-module--postCardTitle--042e3";
export var postCardWrapper = "BlogList-module--postCardWrapper--75b34";
export var posts = "BlogList-module--posts--78d97";