import { Link, navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";

import * as styles from "./BlogList.module.css";
import Button from "../Button";
import Icon from "../Icon";
import { slugify } from "../../utils/helpers";

const BlogList = ({ posts, pillar, category, uniquePillars }) => {
  let pillarList = ["All"],
    categoryList = ["All"];

  let isBlogHome = true;
  let latestPosts = { list: [], use: false };
  let indicesToSkip = [0];

  uniquePillars.forEach((pillar) => {
    latestPosts[pillar.pillar] = {};
    latestPosts.list.push(pillar.pillar);
    pillarList.push(pillar.pillar);
  });

  if (pillar) {
    isBlogHome = false;
    uniquePillars
      .find((uniquePillar) => uniquePillar.pillar === pillar)
      .categories.forEach((category) => {
        if (!categoryList.includes(category)) {
          categoryList.push(category);
        }
      });
  }

  if (isBlogHome) {
    latestPosts.use = true;
    latestPosts.list.forEach((pillar) => {
      latestPosts[pillar] = posts.find((post, index) => {
        if (post.node.pillar.toLowerCase() === pillar.toLowerCase()) {
          if (!indicesToSkip.includes(index)) {
            indicesToSkip.push(index);
          }
          return true;
        }
      });
    });
  }

  const POSTS_PER_PAGE = 12;
  let numOfPosts = posts.length - indicesToSkip.length;

  const blogPages = Math.ceil(numOfPosts / POSTS_PER_PAGE);

  const [page, setPage] = useState(1);
  const [currentPosts, setCurrentPosts] = useState(getPageOfPosts());

  function getPageOfPosts(newPage = page) {
    const indexOfLastPost = newPage * POSTS_PER_PAGE;

    const indexOfFirstPost =
      indexOfLastPost + indicesToSkip.length - POSTS_PER_PAGE;

    const newCurrentPosts = posts.slice(
      indexOfFirstPost,
      indexOfLastPost + indicesToSkip.length
    );

    return newCurrentPosts;
  }

  const goToPage = (newPage) => {
    if (page !== newPage) {
      setCurrentPosts(getPageOfPosts(newPage));
      setPage(newPage);
    }
    navigate("#posts");
  };

  const PostCard = ({ post }) => {
    const { image, slug, title, publishDate, excerpt } = post;

    return (
      <Link className={styles.postCardWrapper} to={slug} title={title}>
        <div className={styles.postCardContent}>
          {image && (
            <GatsbyImage
              image={image.file.gatsbyImageData}
              alt={image.alt}
              className={styles.backgroundImage}
            />
          )}
        </div>
        <div className={styles.postCardTitle}>
          <h6>{title}</h6>
          <i>{publishDate}</i>
          <div
            dangerouslySetInnerHTML={{
              __html: excerpt.childMarkdownRemark.html,
            }}
          />
          <div style={{ color: "#002bed" }}>
            Read This Post{" "}
            <Icon
              props={{
                type: "solid",
                name: "fa-arrow-right",
                color: "brightBlue",
                size: "xl",
              }}
            />
          </div>
        </div>
      </Link>
    );
  };

  const LatestCard = ({ post, showTag }) => {
    const { excerpt, image, slug, title, pillar, publishDate } = post;

    return (
      <div className={styles.latestPostCardContainer}>
        <Link className={styles.latestPostCardWrapper} to={slug} title={title}>
          {image && (
            <GatsbyImage
              image={image.file.gatsbyImageData}
              alt={image.alt}
              className={styles.backgroundImage}
            />
          )}
          {showTag && <span className={styles.pillarTag}>{pillar}</span>}
        </Link>
        <Link to={slug} title={title}>
          <div className={styles.latestPostCardContent}>
            <div className={styles.latestPostCardTitle}>
              <h4 style={{ marginBottom: "10px" }}>{title}</h4>
              <i>{publishDate}</i>
              <div
                dangerouslySetInnerHTML={{
                  __html: excerpt.childMarkdownRemark.html,
                }}
                className={styles.latestExcerpt}
              />

              <div style={{ color: "#002bed" }}>
                Read This Post{" "}
                <Icon
                  props={{
                    type: "solid",
                    name: "fa-arrow-right",
                    color: "brightBlue",
                    size: "xl",
                  }}
                />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  const Pagination = () => {
    let pages = [];
    for (let index = 0; index < blogPages; index++) {
      pages.push(index + 1);
    }

    return pages.length > 1 ? (
      <div className={styles.pagination}>
        {pages.map((pageNum, index) => {
          return (
            <div key={`pagination-${pageNum}`}>
              <Button
                variant={
                  index + 1 === page ? "blogPaginationActive" : "blogPagination"
                }
                hasOnClick={true}
                onClick={() => goToPage(pageNum)}
                type={"submit"}
              >
                {pageNum}
              </Button>
            </div>
          );
        })}
        {page !== blogPages && (
          <div>
            <Button
              variant="blogPaginationArrow"
              hasOnClick={true}
              type="submit"
              onClick={() => goToPage(page + 1)}
            >
              <Icon
                props={{
                  type: "solid",
                  name: "fa-arrow-right",
                  color: "#00345C",
                  size: "md",
                }}
              />
            </Button>
          </div>
        )}
      </div>
    ) : null;
  };

  return (
    <div style={{ padding: "0 40px", overflow: "visible" }}>
      <div style={{ marginBottom: "1.5rem", overflow: "visible" }}>
        <div className={styles.buttonList}>
          {pillarList.map((uniquePillar) => {
            const destination =
              uniquePillar === "All"
                ? "/blog/"
                : `/blog/${slugify(uniquePillar)}/`;

            return (
              <Button
                destination={destination}
                variant={
                  !pillar && uniquePillar === "All"
                    ? "blogButtonActive"
                    : pillar === uniquePillar
                    ? "blogButtonActive"
                    : "blogButton"
                }
              >
                {uniquePillar}
              </Button>
            );
          })}
        </div>
      </div>
      {posts && (
        <div className={styles.categoryContainer}>
          <div>
            <div>
              {pillar && (
                <div className={styles.categoryList}>
                  <select name="categoryList" id="categoryList">
                    {categoryList.map((uniqueCategory) => {
                      const destination =
                        uniqueCategory === "All"
                          ? `/blog/${slugify(pillar)}/`
                          : `/blog/${slugify(pillar)}/${slugify(
                              uniqueCategory
                            )}/`;
                      return (
                        <option
                          value={uniqueCategory}
                          onChange={() => navigate(destination)}
                        >
                          {uniqueCategory}
                        </option>
                      );
                    })}
                  </select>
                  {categoryList.map((uniqueCategory) => {
                    const destination =
                      uniqueCategory === "All"
                        ? `/blog/${slugify(pillar)}/`
                        : `/blog/${slugify(pillar)}/${slugify(
                            uniqueCategory
                          )}/`;

                    return (
                      <p>
                        <Link
                          to={destination}
                          className={
                            !category && uniqueCategory === "All"
                              ? styles.bold
                              : category === uniqueCategory
                              ? styles.bold
                              : null
                          }
                        >
                          {uniqueCategory}
                        </Link>
                      </p>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div>
            {latestPosts.use ? (
              <>
                <h5>Latest Posts</h5>
                <div className={styles.multipleLatest}>
                  {latestPosts.list.map((pillar) => {
                    const post = latestPosts[pillar]?.node;

                    if (post) {
                      return (
                        <LatestCard
                          post={post}
                          key={`latest-${post.id}`}
                          showTag={isBlogHome}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
              </>
            ) : (
              <LatestCard post={posts[0].node} />
            )}

            <div className={styles.posts} id="posts">
              {posts.length > 0 &&
                currentPosts.map(({ node: post }, index) => {
                  return (
                    <PostCard
                      // data-aos="zoom-in"
                      // data-aos-duration="500"
                      post={post}
                      key={post.id}
                    />
                  );
                })}
            </div>
            <Pagination />
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogList;
